import type { Category } from '../types'

export const useCasinoStore = defineStore('casino', () => {
  const { locale } = useI18n()

  const { data: categories, status } = useStFetch('/casino/category/find', {
    method: 'post',
    body: computed(() => ({
      params: {
        language: locale.value as any,
      },
      pagination: {
        page: 0,
        perPage: 500,
        orderBy: [
          {
            fieldName: 'weight' as const,
            sortOrder: 'DESC' as const,
          },
        ],
      },
    })),
    transform: (response) =>
      response.data.map((category) => ({
        ...category,
        name: category.name || category.code,
      })),
    default: () => [],
  })

  const pending = computed(() => status.value === 'pending')

  const mainCategories = computed<Category[]>(() =>
    categories.value
      .filter((category) => category.type === 'main')
      .sort((a, b) => b.weight - a.weight),
  )

  const firstMainCategory = computed(() => mainCategories.value[0])

  const mainPageCategories = computed<Category[]>(() =>
    categories.value.filter((category) => category.type === 'mainPage'),
  )

  const tabCategories = computed<Category[]>(() =>
    categories.value.filter((category) => category.visibleForScrollbar),
  )

  const menuCategories = computed<Category[]>(() =>
    categories.value.filter((category) => category.visibleForLeftMenu),
  )

  const otherCategories = computed<Category[]>(() =>
    categories.value.filter((category) => category.type === 'other'),
  )

  const categoryMap = computed(() =>
    categories.value.reduce<Map<string | number, Category>>((acc, category) => {
      acc.set(category.code, category)
      acc.set(category.id, category)
      return acc
    }, new Map()),
  )

  function getCategoryByCode(code: string): Category | undefined {
    return categoryMap.value.get(code)
  }

  function getCategoryNameById(id: number): Category['name'] | undefined {
    return categoryMap.value.get(id)?.name
  }

  const isLoading = computed(() => pending.value && !categories.value.length)

  return {
    categories,
    mainCategories,
    mainPageCategories,
    tabCategories,
    menuCategories,
    isLoading,
    otherCategories,
    firstMainCategory,
    getCategoryByCode,
    getCategoryNameById,
  }
})
